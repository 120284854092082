import headingBg from './assets/heading-bg.jpg'
/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */
export default {
    heroContainer: {
        position: `relative`,
        pt: [6, 7],
        pb: [4, 5],
        '::before, ::after': {
            display: `flex`,
            flexFlow: `row`,
            justifyContent: `center`,
            alignItems: `center`,
            position: `absolute`,
            content: `" "`,
            width: `full`,
            height: `110%`,
            top: 0,
            right: 0
        },
        '::before': {
            zIndex: -3,
            bg: `white`,
            background: t => `linear-gradient(
        145deg,
        ${t.colors.alpha} 0%,
        ${t.colors.alphaDarker} 100%
      )`
        },
        '::after': {
            zIndex: -2,
            background: `url(${headingBg})`,
            backgroundSize: `cover`,
            opacity: 0.3
        }
    },
    buildBrandContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            size: `full`,
            position: `absolute`,
            right: 0,
            top: `13%`,
            zIndex: -1,
            opacity: 0.3,
            backgroundSize: `40%`
        }
    },
    getStartedContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            size: `full`,
            position: `absolute`,
            top: [`25%`, 0],
            right: 6,
            zIndex: -1,
            backgroundSize: `55%`
        }
    }
}
