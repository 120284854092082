import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '../../blocks/Footer/Block01'
import CustomHeader from "../../blocks/CustomHeader";
import {normalizeBlockContentNodes} from '@blocks-helpers'
import Hero from '@solid-ui-blocks/Hero/Block02'
import theme from './_theme'
import styles from './_styles'

const BlogPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title='Noticias'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <CustomHeader content={content['header-dark']}/>
            <Container title=''variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse/>
            </Container>
            <Blog content={content['latest-blogs']}/>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
  query homepageBlogBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/blog", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default BlogPage
